:root{
  --MainBg: white;
  --SecondaryBg: rgb(172, 171, 171);
  --PrimaryTextColor: black;
  --SepLineColor:  #e0e3e6;
}

.selected {
  background-color: rgb(97, 196, 228); 
}


body{
  background: var(--MainBg);
  overflow: hidden;
  color: var(--PrimaryTextColor);
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

.HeaderDiv{
  border-bottom: 1px solid #e0e3e6;
}

.row-ow{
  margin: 0;
  padding: 0;
}

.SignUPMain{
  background-color: #4d4855; background-image: linear-gradient(147deg, #4d4855 0%, #000000 74%);
  height: 100vh;
  width: 100%;
}
.SignUpFormInput{
  background-color: rgba(255, 255, 255, 0.08)!important;
  color: white!important;
}
.container-ow{
  margin: 0;
  padding: 0;
}
.MainTasksHead{
  color: var(--PrimaryTextColor);
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  margin-top: 24px!important;
}
.t-left{
  text-align: left;
}
.addInput{
  background: var(--MainBg)!important;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: var(--PrimaryTextColor)!important;
}
.btn-custom-tasks{
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
}
.TextGridHeads{
  font-family:'Arial Narrow Bold';
  font-style: italic;
  text-align: left;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: white;
  margin: 26px 0 2px 0;
}
.MainHeadText{
  font-size: 28px;
}
.TaskGridSelector{
  border-right: 1px solid #e0e3e6;
  background: white ;
}
#TaskTree{
  display: flex;
  flex-direction: row;
}
.checkbox-label-container {
  display: flex;
  align-items: center;
}

.checkbox-label-container input {
}

.TaskGridCol{
  font-family: 'Open Sans', sans-serif;
  white-space: nowrap;
  line-height: 18px;
  overflow-x: hidden;
  border-bottom: 1px solid #e0e3e6;
  font-size: 14px;
  font-style: italic;
  text-align: left;
  cursor: default;
  padding: 4px 0 4px 0;
}
.Collapsed{
  display: none!important;
}
.CompName{
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  color: #66FCF1;
  font-size: 14px;
}
.font-14{
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
}
.SepLine{
  height: 1px;
  background: var(--SepLineColor);
}
.navdiv-ow{
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #0B0C10;
  font-family: 'Arial Narrow Bold', sans-serif, Helvetica, sans-serif;
}
.Button-options{
  border: 0;
  margin-right: -16px;
  background: transparent;
}
.TaskGridCol {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ListButtonCol {
  margin-left: auto;
}
.list-container{
  background: white;
  border: 0.6px solid #e0e3e6;
}
.list-container-button{
  background: white;
  display: block;
  color: black;
  width: 120px;
  text-align: left;
  line-height: 30PX;
  font-size: 14px;
  border: 0;
  text-decoration: none;
  border-bottom: 1px solid #e0e3e6;
}